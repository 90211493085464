import PropTypes from 'prop-types';
import React from 'react';

import { Box, Container } from "@material-ui/core";
import {
  SectionHowToGetFinancing,
  SectionConsultation,
  SectionLeasingAdvantages,
  SectionLeasingScheme,
} from "@product-site-frontend/shared";
import { graphql } from 'gatsby';

import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import config from '../../config/website';
import logo from '../../static/images/logo.png';
import metaImageAuto from "../../static/images/metaImageAuto.jpg";
import PageBox from '../components/PageBox';
import SectionAboutLeasing from '../components/SectionAboutLeasing';
import SectionBusinessSegments from '../components/SectionBusinessSegments';
import SectionMain from '../components/SectionMain';
import SEO from '../components/SEO';

import '../assets/main.scss';

IndexPage.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.object,
    aboutLeasingContent: PropTypes.object,
    avto: PropTypes.object,
    leasingAdvantagesContent: PropTypes.object,
    leasingSchemeContent: PropTypes.object,
    mainSectionContent: PropTypes.object,
    home: PropTypes.object,
  }),
};

export default function IndexPage({ data }) {
  const {
    aboutLeasingContent,
    avto,
    leasingAdvantagesContent,
    leasingSchemeContent,
  } = data;

  return (
    <PageBox>
      <SEO
        image={metaImageAuto}
        microData={{
          '@context': 'https://www.schema.org',
          '@type': 'Product',
          logo: `${config.siteUrl}${logo}`,
          name: avto.seo[0].title,
          image: metaImageAuto,
          description: avto.main[0].description,
          offers: {
            '@type': 'Offer',
            'priceCurrency': 'RUB',
            'price': `до ${avto.main[0].dealAmount} млн.`
          }
        }}
        pageData={avto.seo[0]}
      />
      <Breadcrumbs
        breadcrumbs={[
          {
            href: '/',
            text: 'Домашняя страница'
          },
          {
            text: 'Лизинг авто'
          }
        ]}
        sx={{ breadcrumbsLine: { borderBottom: '1px solid #ebebeb' } }}
      />

      <Box component="section" sx={{ background: "#ff8c0f", color: "#ffffff", fontSize: { xs: '12px', md: '16px' }, fontWeight: "600", padding: "6px 14px" }} textAlign={"center"}>
        <Container>
          Продукт автолизинг от Аренза работает в пилотном режиме до конца года! Ежедневно совершенствуем процессы для вас.
        </Container>
      </Box>

      <SectionMain content={avto.main[0]} isLeasingAuto={true} />

      <SectionAboutLeasing
        content={{ ...aboutLeasingContent, ...avto.aboutLeasing[0] }}
      />
      <SectionBusinessSegments content={avto.businessSegments[0]} hideBGTitle={true} />
      <SectionLeasingScheme content={{
        ...leasingSchemeContent, ...avto.leasingScheme[0]
      }} />
      <SectionLeasingAdvantages content={leasingAdvantagesContent} />
      <SectionHowToGetFinancing content={avto.financing[0]} />
      <SectionConsultation />
    </PageBox>
  );
}

export const query = graphql`
  query AvtoPageQuery {
    avto: strapiAvto {
      seo {
        title
        description
        meta {
          content
          name
        }
      }
      main {
        title
        description
        term
        dealAmount
        advanceRate
        bgDesktop {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
        bgMobile {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
      aboutLeasing {
        title
        description
      }
      leasingScheme {
        title
        caption
        steps {
          title
          description
        }
      }
      businessSegments {
        title
        cards {
          text
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
            }
          }
        }
      }
      financing {
        title
        steps {
          title
          description
        }
        bgDesktop {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500, placeholder: NONE)
            }
          }
        }
      }
    }
    leasingSchemeContent: strapiLeasingScheme {
      title
      caption
      steps {
        title
        description
      }
    }
    aboutLeasingContent: strapiAboutLeasing {
      title
      description
      cards {
        text
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
    }
    leasingAdvantagesContent: strapiLeasingAdvantages {
      cards {
        title
        description
        icon {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            }
          }
        }
      }
      title
    }
  }
`;
